import React from 'react';

export default function TermsConditions() {

    return (
        <div className='terms-privacy-container'>
            <h2>bttnusa.com/pages/review Terms and Conditions</h2>
            <br></br>
            Last Modified: September 10, 2020
            <br></br>
            <br></br>
            <b>Acceptance of the Terms of Use</b>
            <br></br>
            These terms of use are entered into by and between You and Yext, Inc. (the "Company", "we" or
            "us"). The following terms and conditions, together with any documents they expressly incorporate
            by reference (collectively, these "Terms of Use"), govern your access to and use of
            bttnusa.com/pages/review, including any content, functionality and services offered on or through
            bttnusa.com/pages/review (the "Website"), whether as a guest or a registered user. Please read the Terms
            of Use carefully before you start to use the Website. By using the Website or by clicking to
            accept or agree to the Terms of Use when this option is made available to you, you accept and
            agree to be bound and abide by these Terms of Use and our Privacy Policy, found at
            bttnusa.com/pages/review/privacypolicy, incorporated herein by reference. If you do not want
            to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website. This
            Website is offered and available to users who are 18 years of age or older. By using this Website,
            you represent and warrant that you are of legal age to form a binding contract with the Company
            and that you have the authority to enter into this Agreement personally, or if on behalf of a
            company entering into these Terms of Use, to bind such company to these Terms of Use. If you do
            not meet all of these requirements, you must not access or use the Website.
            <br></br><br></br><b>Changes to the Terms of Use</b><br></br>
            We may revise and update these Terms of Use from time to time in our sole discretion. All changes
            are effective immediately when we post them and apply to your access to and use of the Website
            thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law
            and Jurisdiction section will not apply to any disputes for which the parties have actual notice
            prior to the date the change is posted on the Website. Your continued use of the Website following
            the posting of revised Terms of Use means that you accept and agree to the changes. You are
            expected to check this page from time to time so you are aware of any changes, as they are binding
            on you.
            <br></br><br></br><b>Accessing the Website </b><br></br>
            We reserve the right to withdraw or amend this Website, and any service or material we provide on
            the Website, in our sole discretion without notice. We will not be liable if for any reason all or
            any part of the Website is unavailable at any time or for any period.
            <br></br><br></br>You are responsible for:<br></br><br></br>
            <ul>
            <li>Making all arrangements necessary for you to have access to the Website.</li>
            <li>Ensuring that all persons who access the Website through your internet connection are aware
                of these Terms of Use and comply with them.
            </li>
            </ul>
            <br></br>
            It is a condition of your use of the Website that all the information you provide on the Website
            is correct, current and complete. You agree that all information you provide through the Website
            is governed by our Privacy Policy, and you consent to all actions we take with respect to your
            information consistent with our Privacy Policy.
            <br></br><br></br>
            <b>Intellectual Property Rights</b>
            <br></br>
            The Website and its entire contents, features and functionality (including but not limited to all
            information, software, text, displays, images, video and audio, and the design, selection and
            arrangement thereof), are owned by the Company, its licensors or other providers of such material
            and are protected by United States and international copyright, trademark, patent, trade secret
            and other intellectual property or proprietary rights laws. These Terms of Use permit you to use
            the Website for your personal, non-commercial use only. You must not reproduce, distribute,
            modify, create derivative works of, publicly display, publicly perform, republish, download, store
            or transmit any of the material on our Website.
            <br></br>
            You must not:
            <ul>
            <li>Modify copies of any materials from this site.</li>
            <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of
                materials from this site.
            </li>
            <li>You must not access or use for any commercial purposes any part of the Website or any
                services or materials available through the Website.
            </li>
            <li>If you print, copy, modify, download or otherwise use or provide any other person with
                access to any part of the Website in breach of the Terms of Use, your right to use the Website
                will cease immediately and you must, at our option, return or destroy any copies of the
                materials you have made. No right, title or interest in or to the Website or any content on
                the Website is transferred to you, and all rights not expressly granted are reserved by the
                Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of
                these Terms of Use and may violate copyright, trademark and other laws.
            </li>
            </ul>
            <br></br>
            <b>Trademarks </b>
            <br></br>
            The Company name, and all related names, logos, product and service names, designs and slogans are
            trademarks of the Company or its affiliates or licensors. You must not use such marks without the
            prior written permission of the Company. All other names, logos, product and service names,
            designs and slogans on this Website are the trademarks of their respective owners.
            <br></br>
            <br></br>
            <b>Prohibited Uses</b>
            <br></br>
            You may use the Website only for lawful purposes and in accordance with these Terms of Use. You
            agree not to use the Website:
            <br></br><br></br>
            <ul>
            <li>In any way that violates any applicable federal, state, local or international law or
                regulation (including, without limitation, any laws regarding the export of data or software
                to and from the US or other countries).
            </li>
            <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by
                exposing them to inappropriate content, asking for personally identifiable information or
                otherwise.
            </li>
            <li>To send, knowingly receive, upload, download, use or re-use any material which does not
                comply with the Content Standards (defined below) set out in these Terms of Use.
            </li>
            <li>To transmit, or procure the sending of, any advertising or promotional material including
                any "junk mail", "chain letter" or "spam" or any other similar solicitation.
            </li>
            <li>To impersonate or attempt to impersonate any other person or entity (including, without
                limitation, by using e-mail addresses or screen names associated with any of the foregoing).
            </li>
            <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the
                Website, or which, as determined by us, may harm the Company or users of the Website or expose
                them to liability.
            </li>
            <li>To send fake or defamatory reviews.</li>
            </ul>
            <br></br>
            Additionally, you agree not to:
            <ul>
            <li>Use the Website in any manner that could disable, overburden, damage, or impair the site or
                interfere with any other party's use of the Website, including their ability to engage in real
                time activities through the Website.
            </li>
            <li>Use any robot, spider or other automatic device, process or means to access the Website for
                any purpose, including monitoring or copying any of the material on the Website.
            </li>
            <li>Use any manual process to monitor or copy any of the material on the Website or for any
                other unauthorized purpose without our prior written consent.
            </li>
            <li>Use any device, software or routine that interferes with the proper working of the
                Website.
            </li>
            <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is
                malicious or technologically harmful.
            </li>
            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the
                Website, the server on which the Website is stored, or any server, computer or database
                connected to the Website.
            </li>
            <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service
                attack.
            </li>
            <li>Otherwise attempt to interfere with the proper working of the Website.</li>
            </ul>
            <br></br>
            <b>User Contributions</b>
            <br></br>
            The Website may contain interactive features (collectively, "Interactive Services") that allow
            users to post, submit, publish, display or transmit to other users or other persons (hereinafter,
            "post") content or materials (collectively, "User Contributions") on or through the Website. All
            User Contributions must comply with the Content Standards set out in these Terms of Use. Any User
            Contribution you post to the site will be considered non-confidential and non-proprietary. By
            providing any User Contribution on the Website, you grant us and our affiliates, the business
            listed on the request form, and service providers, and each of their and our respective licensees,
            successors and assigns the irrevocable, world-wide, perpetual, non-exclusive, royalty-free,
            assignable, sublicensable, transferable right to use, reproduce, modify, perform, display,
            distribute and otherwise disclose to third parties any such material for any purpose.
            <br></br><br></br>You represent and warrant that:
            <br></br><br></br>
            <ul>
            <li>You own or control all rights in and to the User Contributions and have the right to
                grant the license granted above to us and our affiliates and service providers, and each of
                their and our respective licensees, successors and assigns.
            </li>
            <li>All of your User Contributions do and will comply with these Terms of Use.</li>
            </ul>
            <br></br>
            You understand and acknowledge that you are responsible for any User Contributions you submit or
            contribute, and you, not the Company, have fully responsibility for such content, including its
            legality, reliability, accuracy and appropriateness. You agree to irrevocably waive (and cause to
            be waived) any claims and assertions of moral rights or attribution with respect to your User
            Contributions. We are not responsible, or liable to any third party, for the content or accuracy
            of any User Contributions posted by you or any other user of the Website.
            <br></br><br></br><b>Monitoring and Enforcement; Termination</b>
            <br></br>
            We have the right to:
            <ul>
            <li>Remove or refuse to post any User Contributions for any or no reason in our sole
                discretion.
            </li>
            <li>Take any action with respect to any User Contribution that we deem necessary or appropriate
                in our sole discretion, including if we believe that such User Contribution violates the Terms
                of Use, including the Content Standards, infringes any intellectual property right or other
                right of any person or entity, threatens the personal safety of users of the Website or the
                public or could create liability for the Company.
            </li>
            <li>Take appropriate legal action, including without limitation, referral to law enforcement,
                for any illegal or unauthorized use of the Website.
            </li>
            <li>Terminate or suspend your access to all or part of the Website for any or no reason,
                including without limitation, any violation of these Terms of Use.
            </li>
            <br></br>
            </ul>
            Without limiting the foregoing, we have the right to fully cooperate with any law enforcement
            authorities or court order requesting or directing us to disclose the identity or other
            information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS
            THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
            ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM
            ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
            AUTHORITIES. However, we cannot review material before it is posted on the Website and cannot
            ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume
            no liability for any action or inaction regarding transmissions, communications or content
            provided by any user or third party. We have no liability or responsibility to anyone for
            performance or nonperformance of the activities described in this section.
            <br></br><br></br>
            <b>Content Standards</b>
            <br></br>
            These content standards apply to any and all User Contributions and use of Interactive Services.
            User Contributions must in their entirety comply with all applicable federal, state, local and
            international laws and regulations. Without limiting the foregoing, User Contributions must not:
            <ul>
            <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing,
                violent, hateful, inflammatory or otherwise objectionable.
            </li>
            <li>Promote sexually explicit or pornographic material, violence, or discrimination based on
                race, sex, religion, nationality, disability, sexual orientation or age.
            </li>
            <li>Infringe any patent, trademark, trade secret, copyright or other intellectual property or
                other rights of any other person.
            </li>
            <li>Violate the legal rights (including the rights of publicity and privacy) of others or
                contain any material that could give rise to any civil or criminal liability under applicable
                laws or regulations or that otherwise may be in conflict with these Terms of Use and our
                Privacy Policy.
            </li>
            <li>Be likely to deceive any person.</li>
            <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
            <li>Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm
                or annoy any other person.
            </li>
            <li>Impersonate any person, or misrepresent your identity or affiliation with any person or
                organization.
            </li>
            <li>Involve commercial activities or sales, such as contests, sweepstakes and other sales
                promotions, barter or advertising.
            </li>
            <li>Give the impression that they emanate from or are endorsed by us or any other person or
                entity, if this is not the case.
            </li>
            </ul>
            <br></br>
            <b>Copyright Infringement</b><br></br>
            We respect the intellectual property rights of others and attempts to comply with all relevant
            laws. We will review all claims of copyright infringement received and remove any content deemed
            to have been posted or distributed in violation of any such laws.
            Our designated agent under the Digital Millennium Copyright Act (the “Act”) for the receipt of any
            notification of claimed infringement which may be given under that Act is as follows:
            <br></br><br></br>
            Yext, Inc.<br></br>
            Attention: Legal<br></br>
            61 Ninth Avenue<br></br>
            New York, NY 10011<br></br><br></br>
            OR<br></br><br></br>
            Email to: legal@yext.com
            <br></br>
            <br></br>
            If you believe that one of our users, through the use of the Website, copied your work in a way
            that constitutes copyright infringement, please provide our agent with notice in accordance with
            the requirements of the Act, including: (i) a description of the copyrighted work that you believe
            has been infringed; (ii) a description of the location of the material that you believe is
            infringing the copyrighted work; (iii) your address, telephone number and e-mail address; (iv) a
            statement by you that you have a good faith belief that the disputed use is not authorized by the
            copyright owner, its agent or the law; (v) a statement by you, made under penalty of perjury, that
            the information in your notice is accurate and that you are the copyright owner or authorized to
            act on the copyright owner’s behalf; and (vi) an electronic or physical signature of the owner of
            the copyright or the person authorized to act on behalf of the owner of the copyright interest.
            <br></br><br></br>
            <b>Changes to the Website</b><br></br>
            We may update the content on this Website from time to time, but its content is not necessarily
            complete or up-to-date. Any of the material on the Website may be out of date at any given time,
            and we are under no obligation to update such material.
            <br></br><br></br>
            <b>Links from the Website</b><br></br>
            If the Website contains links to other sites and resources provided by third parties, these links
            are provided for your convenience only. This includes links contained in advertisements, including
            banner advertisements and sponsored links. We have no control over the contents of those sites or
            resources, and accept no responsibility for them or for any loss or damage that may arise from
            your use of them. If you decide to access any of the third party websites linked to this Website,
            you do so entirely at your own risk and subject to the terms and conditions of use for such
            websites.
            <br></br>
            <br></br><b>Disclaimer of Warranties</b><br></br>
            You understand that we cannot and do not guarantee or warrant that files available for downloading
            from the internet or the Website will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints to satisfy your particular
            requirements for anti-virus protection and accuracy of data input and output, and for maintaining
            a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR
            ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
            TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA
            OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
            THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
            TO IT. YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
            IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
            ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
            ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
            ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY
            SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
            ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. THE COMPANY HEREBY
            DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
            INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
            PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
            LIMITED UNDER APPLICABLE LAW.
            <br></br>
            <br></br>
            <b>Limitation on Liability</b>
            <br></br>
            IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
            AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
            OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO
            IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
            THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
            SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. To the extent that Company may
            not as a matter of applicable law limit its liability in the way contemplated in this Section,
            then the combined aggregate liability of Company and all Company affiliates under, or otherwise in
            connection with this Terms of Use shall not exceed fifty U.S. dollars.<br></br>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
            LAW.
            <br></br><br></br>
            <b>Indemnification</b>
            <br></br>
            You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and
            service providers, and its and their respective officers, directors, employees, contractors,
            agents, licensors, suppliers, successors and assigns from and against any claims, liabilities,
            damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees)
            arising out of or relating to your violation of these Terms of Use or your use of the Website,
            including, but not limited to, your User Contributions, any use of the Website's content, services
            and products other than as expressly authorized in these Terms of Use or your use of any
            information obtained from the Website.
            <br></br><br></br>
            <b>Governing Law and Jurisdiction</b>
            <br></br>
            All matters relating to the Website and these Terms of Use and any dispute or claim arising
            therefrom or related thereto (in each case, including non-contractual disputes or claims), shall
            be governed by and construed in accordance with the internal laws of the State of New York without
            giving effect to any choice or conflict of law provision or rule (whether of the State of New York
            or any other jurisdiction). Any legal suit, action or proceeding arising out of, or related to,
            these Terms of Use or the Website shall be instituted exclusively in the federal courts of the
            United States or the courts of the State of New York, in each case located in the City of New York
            and County of New York. You waive any and all objections to the exercise of jurisdiction over you
            by such courts and to venue in such courts. Notwithstanding anything to the contrary, Company may
            seek injunctive relief in any court having jurisdiction to protect its intellectual property or
            confidential or proprietary information.
            <br></br>
            <br></br>
            <b>Arbitration</b>
            <br></br>
            At Company's sole discretion, it may require You to submit any disputes arising from the use of
            these Terms of Use or the Website, including disputes arising from or concerning their
            interpretation, violation, invalidity, non-performance, or termination, to final and binding
            arbitration under the Rules of Arbitration of the American Arbitration Association applying New
            York law.
            <br></br><br></br>
            <b>Limitation on Time to File Claims</b>
            <br></br>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR
            THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE,
            SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            <br></br>
            <br></br>
            <b>Waiver and Severability</b>
            <br></br>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be
            deemed a further or continuing waiver of such term or condition or a waiver of any other term or
            condition, and any failure of the Company to assert a right or provision under these Terms of Use
            shall not constitute a waiver of such right or provision. If any provision of these Terms of Use
            is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent
            such that the remaining provisions of the Terms of Use will continue in full force and effect.
            <br></br>
            <br></br>
            <b>Entire Agreement</b>
            <br></br>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you
            and Company with respect to the Website and supersede all prior and contemporaneous
            understandings, agreements, representations and warranties, both written and oral, with respect to
            the Website.
            <br></br>
            <br></br>
            <b>Your Comments and Concerns</b>
            <br></br>This website is operated by bttn Technologies and utilizes tools from Yext, Inc., 61 Ninth Avenue, New York, NY 10011.
        </div>
    )

}