import React from 'react';

export default function PrivacyPolicy() {

    return (
        <div className='terms-privacy-container'>
            <h2>bttnusa.com/pages/review Privacy policy</h2>
            <br></br>
            (Last Updated: September 10th, 2020)
            <br></br>
            <br></br>
            This Privacy Policy (this “Privacy Policy”) discloses the privacy practices of Yext, Inc (“Yext,”
            “we,” or “us”) for bttnusa.com/pages/review (the “Website”). You reached the Website because you received
            a request from the business listed on the request form (the “Business”). This Privacy Policy
            explains our key practices for any information that you submit to us through the Website or that
            we otherwise collect in operating our review services. If you want more information about our data
            collection practices generally, please click <a href="http://www.yext.com/privacy-policy/" target='_blank' rel='noreferrer'>here</a>.
            <br></br>
            <br></br>
            <b>Collection</b>. <br></br> If you choose to provide a review, we will collect any information you
            provide, including personal information, on behalf of the Business that you review. We collect
            information about your use of the review service (for example, when you receive or open a review
            invitation). We may also collect information about you, such as your name and email address, when
            the Business provides this information to us. We do not collect information on the Website about
            your online activities over time or across different websites, and we do not use third parties to
            collect such information when you visit the Website.
            <br></br>
            <br></br>
            <b>Use</b>. <br></br> We may use any information that we collect to provide services to the Business.
            We may also use this information to compile statistical and other aggregate data related to the
            performance and operation of the review service. We may further use this aggregated or
            de-identified data in our discretion, such as for research and development, reporting, analytics
            and benchmarking purposes using cookies.
            <br></br>
            <br></br>
            <b>Disclosure</b>. We will share with the Business any information you provide when submitting a
            review. Any information we provide to the Business will be subject to the Business’s privacy
            practices and may be used or disclosed by the Business for the purposes set forth in their privacy
            practices including, but not limited to, operating and marketing the Business. We reserve the
            right to transfer and/or sell aggregate or de-identified data about Reviewers for lawful purposes,
            but not in a form that could identify you. We may further disclose your information if required to
            do so by law or in the good faith belief that such action is necessary to (a) comply with a
            subpoena or similar legal obligation, (b) protect and defend our rights or property, (c) act in
            urgent circumstances to protect the personal safety of users of the Website or the public, (d)
            protect against legal liability, or (e) or as otherwise required or permitted by law.
            Additionally, as we develop our business, we might sell or buy businesses or assets. In the event
            of an actual or potential corporate sale, merger, reorganization, acquisition, joint venture
            dissolution or similar event, including in bankruptcy, personal information may be all or part of
            the transferred assets.
            <br></br>
            <br></br>
            <b>Changes to this Policy</b>. If we update this Privacy Policy, we will post the revised version
            here. We encourage you to check back for updates.
            <br></br>
            <br></br>
            <b>Contacting Us</b>. You have the right to access, correct, and delete your personal data as well
            as the right to object to the processing of personal data, including for marketing purposes. If
            you have any questions about this Privacy Policy or our privacy practices, please feel free to
            contact us at privacy@yext.com.
        </div>
    )

}