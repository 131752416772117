import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import bttnLogo from "./bttn-logo.png";
import ReviewForm from './components/reviewForm';
import TermsConditions from './components/TermsConditions'
import PrivacyPolicy from "./components/privacyPolicy";

function App() {

  return (
      <div className="App">
        <Router>
          <Switch>
            <Route path='/' exact>
              <header className="App-header">
                <img src={bttnLogo} alt="bttn Logo" id="logo" className="logo-bounce"></img>
                <ReviewForm />
              </header>
            </Route>
            <Route path='/termsandconditions'>
              <TermsConditions />
            </Route>
            <Route path='/privacypolicy'>
              <PrivacyPolicy />
            </Route>
          </Switch>
        </Router>
      </div>
  );
}

export default App;