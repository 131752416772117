import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button, Checkbox } from '@material-ui/core';
import { Rating } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";
const axios = require('axios')

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  }
}));

export default function ReviewForm() {
  const classes = useStyles();

  const [ratingValue, setRatingValue] = useState(0);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [nameError, setNameError] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [captchaComplete, setCaptchaComplete] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  let recaptchaKey = `${process.env.REACT_APP_RECAPTCHA_KEY}`;
  
  const queryURL = `https://liveapi.yext.com/v2/accounts/${process.env.REACT_APP_YEXT_ACCOUNT_ID}/reviewSubmission?api_key=${process.env.REACT_APP_YEXT_API_KEY}&v=20210902`;

  function validateName(name) {
    var regName = /^[a-zA-Z-' ]+(?:(?:, |[ ])[a-zA-Z-.]+)$/;
    return regName.test(String(name).toLowerCase());
  }

  function validateEmail(email) {
    const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  }

  function onCaptchaChange() {
    setCaptchaError(false)
    setCaptchaComplete(true)
  }

  const submitReview = (e) => {
    e.preventDefault();
    if (!submitted) {
      setSubmitted(true);
    }
    if (feedbackValue !== "" && validateName(nameValue) && validateEmail(emailValue) && ratingValue !== 0 && captchaComplete && termsAccepted) {

      axios.post(queryURL, {
        "entity": {
          "id": `${process.env.REACT_APP_YEXT_ENTITY_ID}`
        },
        "authorName": nameValue,
        "rating": ratingValue,
        "content": feedbackValue,
        "authorEmail": emailValue 
      },{
        headers: {
          'Content-Type': 'application/json'
        }
      })

      // end API call
      document.getElementById('reviewForm').style.transform = 'translateY(-50vh)';
      document.getElementById('reviewForm').style.opacity = 0;
      document.getElementById('thankYouMsg').style.transform = 'translateY(-50vh)';
      document.getElementById('thankYouMsg').style.opacity = 100;
    } else {
      if (ratingValue === 0 || ratingValue === null) {
        setRatingError(true)
      }
      if (feedbackValue === "") {
        setFeedbackError(true)
      }
      if (!validateName(nameValue)) {
        setNameError(true)
      }
      if (!validateEmail(emailValue)) {
        setEmailError(true)
      }
      if (!captchaComplete) {
        setCaptchaError(true)
      }
      if (!termsAccepted) {
        setTermsError(true)
      }
    }
  }

  return (
    <>
      <form className={classes.root} id="reviewForm" noValidate autoComplete="off">
        <p className="formTitle">
          How would you rate your experience with bttn?
        </p>
        <div className="formField">
          <Rating  
            id="rating-stars" 
            name="rating"
            style={{ fontSize: 50 }} 
            value={ratingValue}
            onChange={(event, newVal) => {
              setRatingValue(newVal)
              if (submitted) {
                if (newVal === 0 || newVal === null) {
                  setRatingError(true)
                } else if (ratingError && newVal !== 0) {
                  setRatingError(false)
                }
              }
            }}
          />
          {ratingError ? (
            <>
            <sup className="ratingAst">*</sup>
            <p className="ratingError">Please select 1-5</p>
            </>
          ):(
            <></>
          )}
        </div>
        <div className="formField">
          <TextField 
            required
            id="feedback-field" 
            type="text" 
            label="Your Review" 
            autoComplete="off"
            value={feedbackValue}
            onChange={e => {
              setFeedbackValue(e.target.value)
              if (submitted) {
                if (!feedbackError && e.target.value === "") {
                  setFeedbackError(true);
                } else if (e.target.value !== "") {
                  setFeedbackError(false);
                }
              }
            }}
            error={feedbackError}
            helperText={feedbackError ? 'Invalid entry' : ''}
          />
        </div>
        <div className="formField">
          <TextField 
            required 
            id="name-field" 
            label="First name, last initial" 
            placeholder="Ex: Matthew H"
            value={nameValue}
            onChange={e => {
              setNameValue(e.target.value)
              if (submitted) {
                if (!nameError && !validateName(e.target.value)) {
                  setNameError(true);
                } else if (validateName(e.target.value)) {
                  setNameError(false);
                }
              }
            }}
            error={nameError}
            helperText={nameError ? 'Invalid entry' : ''}
          />
        </div>
        <div className="formField">
          <TextField 
            required
            id="email-field" 
            label="Email" 
            placeholder="Ex: matthew.h@gmail.com"
            value={emailValue}
            onChange={e => {
              setEmailValue(e.target.value)
              if (submitted) {
                if (!emailError && !validateEmail(e.target.value)) {
                  setEmailError(true);
                } else if (validateEmail(e.target.value)) {
                  setEmailError(false);
                }
              }
            }}
            error={emailError}
            helperText={emailError ? 'Invalid entry' : ''}
          />
        </div>
        <div id='recaptcha-container'>
          <ReCAPTCHA
            theme='dark'
            sitekey={recaptchaKey}
            onChange={onCaptchaChange}
          />
          {captchaError ? (
            <sup className="captchaAst">*</sup>
          ):(
            <></>
          )}
        </div>
        {captchaError ? (
          <p className='captchaError'>Captcha Required</p>
        ):(
          <></>
        )}
        <p className='privacyText'>We use a third party review handling service. For more details regarding the use of the information submitted on this page, you can visit their {<Link to="/privacypolicy">privacy policy.</Link>}</p>
        <div className='terms-container'>
          <Checkbox
            size="small"
            color="primary"
            onChange={(e) => {
              setTermsAccepted(!termsAccepted)
              if (submitted) {
                if (e.target.checked) {
                  setTermsError(false)
                } else {
                  setTermsError(true)
                }
              }
            }}
          />
          <span>I accept the {<Link to="/termsandconditions">Terms of Service</Link>}</span>
          {termsError ? (
            <sup className="termsAst">*</sup>
          ):(
            <></>
          )}
        </div>
        {termsError ? (
          <p className='termsError'>Required</p>
        ):(
          <></>
        )}
        <div>
          <Button variant="contained" color="primary" onClick={(e) => submitReview(e)}>
            Submit Review
          </Button>
        </div>
      </form>
      <p id="thankYouMsg">Thank you for submitting your review! We greatly appreciate it.</p>
    </>
  );
}